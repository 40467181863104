<template>
  <div class="loan">
    <ul class="loan-container">
      <li class="box-list" v-for="(item, index) in list"
        :key="index">
        <div class="icon-image">
          <img :src="item.imageUrl" alt="">
        </div>
        <div class="box-content">
          <div class="title">{{ item.name }}</div>
          <p>{{ item.title }}</p>
          <p>{{ item.resume }}</p>
        </div>
        <div class="box-btn" @click="jumpUrl(item.jumpUrl)">
          <span>{{ item.buttonText }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { contentDetailListApi } from '@/api/base1';
import { userAgentType } from '@/utils';
import { getAppStatus } from '@/utils/app';

export default {
  name: 'FindLoan',
  data() {
    return {
      list: [],
    };
  },
  computed: {
    cuserId() {
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      return getAppStatus().cuserId;
    },
    appInfo() {
      let res = '{}';
      if (window?.ios?.jzAppInfo) {
        res = window.ios.jzAppInfo();
      }
      if (window?.android?.jzAppInfo) {
        res = window.android.jzAppInfo();
      }
      return JSON.parse(res);
    },
    id() {
      return this.$route.query.id;
    },
  },
  mounted() {
    document.title = '信用借贷';
    this.initData();
  },
  methods: {
    async initData() {
      let res;
      let {
        version,
        channel,
        osType,
        source,
        name,
      } = this.appInfo;
      const data = {
        // pageSize: 50,
        pageNow: 1,
        cuserId: this.cuserId,
        id: this.id,
      };
      const header = {
        version,
        osType,
        source,
        name,
        channel,
        cuserId: this.cuserId,
      };
      // %E5%AE%98%E7%BD%91
      // %25E5%25AE%2598%25E7%25BD%2591
      console.log('channel--', channel);
      try {
        res = await contentDetailListApi(data, header);
      } catch (e) {
        console.log(e);
        this.$toast(e?.desc || '网络异常');
      }
      if (res?.code === 1) {
        let list = res?.results || [];
        let response = [];
        if (list.length) {
          let listData = list.length > 1 ? list[1] : list[0];
          listData.findPageContentDto.forEach((item, index) => {
            let {
              name,
              resume,
              title,
              image,
              buttonText,
            } = item.dto[0];
            response.push({
              name,
              resume,
              title,
              imageUrl: image.imageUrl,
              buttonText,
              jumpUrl: userAgentType().isPhone ? image.iosUrl : image.androidUrl,
            });
          });
          this.list = response;
        }
      }
    },
    jumpUrl(url) {
      window.location.href = url;
    },
  },
};
</script>
<style lang="scss" scoped>
  .loan {
    font-family: PingFangSC-Medium, PingFang SC;
  }
  .loan-container {
    margin: 0 30px;
  }
  .box-list {
    display: flex;
    border-bottom: 1PX solid #f4f5f6;
    // justify-content: space-around;
    align-items: center;
    padding: 27px 0;
    .icon-image {
      width: 90px;
      img {
        width: 100%;
      }
      margin-right: 33px;
    }
    .box-content {
      .title {
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        line-height: 42px;
      }
      > p {
        font-size: 22px;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
        margin-top: 4px;
      }
    }
    .box-btn {
      width: 126px;
      height: 54px;
      background: linear-gradient(90deg, #EA524F 0%, #FF9669 100%);
      border-radius: 42px;
      line-height: 54px;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      color: #FFFFFF;
      margin-left: auto;
    }
  }
</style>
